export const aplCategory = [
  { value: "batsman", category: "Batsman" },
  { value: "bowler", category: "Bowler" },
  { value: "all-rounder", category: "All Rounder" },
];
export const aflCategory = [
  { value: "defender", category: "Defender" },
  { value: "forward", category: "Forward" },
  { value: "goal-keeper", category: "Goal Keeper" },
];
