import React, { useState } from "react";
import { ToastsContainer, ToastsStore } from "react-toasts";
import LoadingSpinner from "../components/Spinner";
import { registerEvents } from "../api/registerVolunteer";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

const event = {
  DANCE: [
    "HOOK STEP",
    "CHOREO NIGHT",
    "SOLO FUSION",
    "NATYA MAYURI",
    "DANCE BATTLE",
  ],
  MUSIC: ["CLASSICAL SWARA", "VADYA BATTLE", "BATTLE OF BANDS"],
  GAMING: ["VALORANT", "BGMI", "FIFA 23", "FREE FIRE"],
  ADVENTURE: [
    "FIGHT FOR THE FITTEST",
    "SQUID GAME",
    "TUG OF WAR",
    "ECO CHALLENGE",
    "ADVENTURE RUN",
  ],
  "LIFE STYLE": ["EL SENOR", "ATTIRE SPECTRA", "LA SENORITA"],
  MISCELLANEOUS: [
    "MAP THE SNAP",
    "KITCHEN MAYHEM",
    // "SOAP BOX RACING",
    "HEIST HAVOK",
    "SKILL TO WIN IT",
  ],
  LITERATURE: ["MIC CHECK", "STANDUP COMEDY ©"],
  DRAMATICS: ["STREET PLAY", "MONO ACTING", "SKIT"],
  TECH: ["SCAVENGER'S HUNT", "CODE HIDE", "CODING MASTER", "MOCK IPL"],
  INTRACOLLEGE: ["TREASURE HUNT", "LAGORI", "DODGE BALL", "MR. AND MS. ETHNIC"],
  AKV: ["ACHARYA KANNADIGA KANNADATHI", "OGATTINALLI OBATTU", "MUSSANJE MAATU"],
  AFF: ["SHORT MOVIE MAKING", "CHARACTER REPLY", "COS PLAY"],
  "FINE ARTS": ["BEST OUT OF WASTE", "PAINTING", "DOODLING"],
  "NATURE WATCH": ["HABBATHON", "TREKKING", "BANNERGHATA"],
  CENTRALIZED: [
    "HABBA LAUNCH",
    "AGT-FGT",
    "STAND UP COMEDY SHOW",
    "BODY BUILDING",
    "INDIAN PARLIAMENT",
  ],
  "ACHARYA MOTOR SPORTS CLUB": ["SOAP BOX RACING"],
};

export const Events = () => {
  const ref = useRef(null);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    registerEvents(formData, setLoading, ref);
  };

  const [formData, setFormData] = useState({ isAcharyan: false });

  const handleInput = (e) => {
    if (e.target.name === "college") {
      setFormData({ ...formData, department: null });
      var mySelect = document.getElementById("department");
      mySelect.selectedIndex = 0;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <form id="vForm" onSubmit={(e) => handleSubmit(e)}>
        <div className="row register-form">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="checkbox"
                value="MALE"
                name="isAcharyan"
                onChange={(e) =>
                  setFormData({ ...formData, isAcharyan: e.target.checked })
                }
              />{" "}
              &nbsp;Are you an Acharyan?
              {formData.isAcharyan && (
                <div style={{ fontSize: "10px" }}>Acharya Email-ID only!</div>
              )}
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="email"
                required
                placeholder="Email"
                name="email"
                autoComplete="off"
                onChange={(e) => handleInput(e)}
              />
              {/* <div style={{ fontSize: "10px" }}>Acharya Email-ID Only!</div> */}
            </div>

            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Name"
                name="name"
                onChange={(e) => handleInput(e)}
              />
              {/* <div style={{ fontSize: "10px" }}>Name as per ID-Card</div> */}
            </div>

            <div className="form-group">
              <select
                placeholder="Event Type"
                className="form-control"
                required
                name="eventType"
                onChange={(e) => handleInput(e)}
              >
                <option selected defaultValue="">
                  Choose Event Type
                </option>
                {Object.keys(event).map((event, index) => {
                  return (
                    <option key={index} value={event}>
                      {event}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <select
                placeholder="Event Name"
                className="form-control"
                id="department"
                required
                name="eventName"
                onChange={(e) => handleInput(e)}
              >
                <option selected defaultValue="">
                  Choose Event Name
                </option>
                {formData.eventType &&
                  event[formData.eventType].map((eventName, index) => {
                    return (
                      <option key={index} value={eventName}>
                        {eventName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <input
                maxLength={10}
                className="form-control"
                type="text"
                pattern="[0-9]{10}"
                placeholder="Phone Number"
                name="phone"
                onChange={(e) => handleInput(e)}
              />
            </div>

            <div onChange={(e) => handleInput(e)}>
              <input type="radio" value="MALE" name="gender" /> Male &nbsp;
              <input type="radio" value="FEMALE" name="gender" /> Female
            </div>
          </div>

          <div id="captcha" className="captcha-holder">
            <ReCAPTCHA
              ref={ref}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={(value) => {
                setFormData({ ...formData, token: value });
                setVerified(true);
              }}
            />
          </div>

          {verified && (
            <div id="register-btn" style={{ margin: "15px auto 0 auto" }}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type="submit"
                  style={{
                    background: "#0DABF1",
                    color: "white",
                    borderRadius: "20px",
                    fontWeight: 500,
                    padding: "3px 22px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Register
                </button>
              )}
            </div>
          )}
          <div id="note-success" className="image-size caution note-email">
            Registration Successful, Check Mail
          </div>
          <div className="image-size caution note-email">
            For any Queries Contact Us on :{" "}
            <a href="mailto:acharyahabba@acharya.ac.in">
              acharyahabba@acharya.ac.in
            </a>
          </div>
        </div>
      </form>
      <ToastsContainer store={ToastsStore} position="top_center" />
    </div>
  );
};
