// import Apl from "./Apl";

import AFLRegister from "./AFLRegister";

const AFL = () => {
  return (
    <div>
      <div className="container register">
        <div className="row">
          <div className="col-md-3 register-left">
            <img src="/apl-LOGO.png" alt="" />
            <h3>Welcome!</h3>
            <p>
              Please enter the following details to register for <br /> AFL-2024
            </p>
          </div>
          <div className="col-md-9 register-right">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <h3 className="register-heading">
                  Acharya Football League - 9{" "}
                  {/* Registrations Closed ! <br />
                  Visit us next year */}
                </h3>
                <AFLRegister />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AFL;
