import React, { useState } from "react";
import { colleges } from "../resources/colleges";
import { renderDepartments } from "../resources/departments";
import { aflCategory } from "../resources/aplCategory";
import { years } from "../resources/year";
import { ToastsContainer, ToastsStore } from "react-toasts";
import Spinner from "../components/Spinner";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import "../css/Apl.css";
import axios from "axios";

const AFLRegister = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    auid: "",
    college: "",
    department: "",
    type: "Student",
    phone: "",
    aflID: null,
    photoUrl: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/afl/register",
        formData
      );
      ToastsStore.success(response.data.message);
    } catch (error) {
      ToastsStore.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    if (e.target.name === "college") {
      setFormData({ ...formData, department: null });
      var mySelect = document.getElementById("department");
      mySelect.selectedIndex = 0;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const readImage = async (e) => {
    let file = e.target.files[0];
    if (!file) {
      ToastsStore.error("No Image Selected");
      return;
    }
    if (file.size > 500000) {
      ToastsStore.error("Please upload a file smaller than 500KB");
      return;
    }
    try {
      setLoading(true);
      await axios.post(process.env.REACT_APP_API_URL + "/afl/check", {
        email: formData.email,
        type: formData.type,
      });
      const fileName = `${formData.email}-${uuidv4()}`;
      const imageRef = ref(storage, `/AFL-2024/${fileName}`);
      await uploadBytes(imageRef, e.target.files[0]);
      const url = await getDownloadURL(ref(storage, `/AFL-2024/${fileName}`));
      setFormData({ ...formData, photoUrl: url });
      setLoading(false);
      ToastsStore.success("Image Uploaded Successfully");
    } catch (error) {
      document.querySelector("#photo").value = "";
      setLoading(false);
      ToastsStore.error(error.response.data.message);
    }
  };

  return (
    <>
      <form id="myForm" onSubmit={(e) => handleSubmit(e)}>
        <div className="row register-form">
          <div className="col-md-6">
            <div className="form-group has-success">
              <div
                name="type"
                required
                id="register-type"
                style={{ marginBottom: "8px" }}
              >
                <input
                  onClick={() => {
                    setFormData({
                      college: null,
                      type: "Faculty",
                      email: null,
                      photoUrl: null,
                    });
                    document.getElementById("myForm").reset();
                  }}
                  defaultChecked={formData.type === "Faculty" ? true : false}
                  type="radio"
                  value="Faculty"
                  id="faculty"
                  name="type"
                />
                <label htmlFor="faculty">Faculty</label>
                <input
                  onClick={() => {
                    setFormData({
                      college: null,
                      type: "Student",
                      email: null,
                      photoUrl: null,
                    });
                    document.getElementById("myForm").reset();
                  }}
                  defaultChecked={formData.type === "Student" ? true : false}
                  type="radio"
                  value="Student"
                  id="student"
                  name="type"
                />
                <label htmlFor="student">Student</label>
              </div>

              <input
                name="email"
                onChange={(e) => handleInput(e)}
                required
                className="form-control"
                type="email"
                // pattern="^[A-Za-z0-9]+(.|_)+[A-Za-z0-9]+@+acharya.ac.in$"
                placeholder="Email"
                autoComplete="off"
              />
              <div style={{ fontSize: "10px" }}>Acharya Email-ID only!</div>
            </div>

            <div className="form-group has-success">
              <input
                name="name"
                onChange={(e) => handleInput(e)}
                required
                type="text"
                className="form-control"
                placeholder="Name"
              />
              <div style={{ fontSize: "10px" }}>Name as per in ID-Card</div>
            </div>

            <div className="form-group">
              <input
                name="auid"
                onChange={(e) => handleInput(e)}
                required={formData.type === "Faculty" ? false : true}
                style={{
                  display: formData.type === "Faculty" ? "none" : "block",
                }}
                disabled={formData.type === "Faculty" ? true : false}
                type="text"
                className="form-control"
                placeholder="AUID"
              />
            </div>

            <div className="form-group">
              <input
                name="phone"
                onChange={(e) => handleInput(e)}
                required
                maxLength={10}
                type="text"
                className="form-control"
                placeholder="Phone No"
              />
            </div>

            <div className="form-group">
              <select
                name="college"
                onChange={(e) => handleInput(e)}
                required
                className="form-control"
                placeholder="College"
              >
                <option selected value="">
                  Choose Your Institute
                </option>
                {colleges.map((college, index) => {
                  return (
                    <option
                      key={index}
                      value={`${college.value} - ${college.college}`}
                    >
                      {college.college}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <select
                placeholder="department"
                className="form-control"
                // required
                id="department"
                name="department"
                onChange={(e) => handleInput(e)}
              >
                <option selected value="">
                  Choose Your Department
                </option>
                {formData.college &&
                  renderDepartments(formData.college).map(
                    (department, index) => {
                      if (
                        formData.type === "Student" &&
                        department.value === "CPRD"
                      ) {
                        // eslint-disable-next-line
                        return;
                      }
                      if (
                        formData.type === "Student" &&
                        (department.value === "HKPY" ||
                          department.value === "MTNS")
                      ) {
                        // eslint-disable-next-line
                        return;
                      }
                      if (
                        formData.type === "Faculty" &&
                        department.value === "others"
                      ) {
                        // eslint-disable-next-line
                        return;
                      }

                      return (
                        <option
                          key={index}
                          value={`${department.value} - ${department.department}`}
                        >
                          {department.department}
                        </option>
                      );
                    }
                  )}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <select
                name="year"
                onChange={(e) => handleInput(e)}
                required={formData.type === "Faculty" ? false : true}
                style={{
                  display: formData.type === "Faculty" ? "none" : "block",
                }}
                disabled={formData.type === "Faculty" ? true : false}
                placeholder=""
                className="form-control"
              >
                <option selected value="">
                  Year Of Study
                </option>
                {years.map((year, index) => {
                  return (
                    <option key={index} value={year.year}>
                      {year.year}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <select
                name="category"
                onChange={(e) => handleInput(e)}
                required
                id="category"
                className="form-control"
              >
                <option selected value="">
                  Select Category
                </option>
                {aflCategory.map((category, index) => {
                  return (
                    <option key={index} value={category.category}>
                      {category.category}
                    </option>
                  );
                })}
              </select>
            </div>

            <input
              type="file"
              required
              id="photo"
              name="photo"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={(e) => readImage(e)}
            />
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          !formData.photoUrl && (
            <div className="btn-up-wrapper">
              <button
                type="button"
                disabled={loading}
                onClick={(e) => {
                  formData.email
                    ? document.getElementById("photo").click()
                    : ToastsStore.error("Enter Email to Continue");
                }}
                style={{
                  background: "#0DABF1",
                  color: "white",
                  borderRadius: "20px",
                  fontWeight: 500,
                  padding: "3px 22px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Upload Photo
              </button>
              <div className="image-size">Size Limit: 500KB</div>
              <div className="image-size caution">
                Upload Passport Size Photo, Otherwise You will be Disqualified
              </div>
              <div className="image-size caution">
                Any wrong details given will lead to Disqualification.
              </div>
              <div
                className="image-size caution"
                style={{
                  lineHeight: "14.44px",
                }}
              >
                After submitting, expect an application form PDF in your email
                soon. <br /> Follow the instructions within to approve your
                application.
              </div>
              <div
                className="image-size caution note-email"
                style={{ position: "relative", bottom: "15px" }}
              >
                For any Queries Contact Us on :{" "}
                <a href="mailto:acharyahabba@acharya.ac.in">
                  acharyahabba@acharya.ac.in
                </a>
              </div>
            </div>
          )
        )}

        {!loading && formData.photoUrl && (
          <div className="btn-register">
            <button
              type="submit"
              disabled={loading}
              style={{
                cursor: "pointer",
                background: "#0DABF1",
                color: "white",
                borderRadius: "20px",
                fontWeight: 500,
                padding: "3px 22px",
                border: "none",
              }}
            >
              Register
            </button>
          </div>
        )}
      </form>
      <ToastsContainer store={ToastsStore} position="top_center" />
    </>
  );
};

export default AFLRegister;
